const colors = {
  secondary: "#828081",

  border: "#F1F1F1",

  plus: "#9B205D",

  screenBackground: "#F6F6F6",
};

export default colors;
